<template>
  <div class="vip_center">
    <div class="left_page page">
      <div class="userInfo">
        <el-avatar :size="60" :src="userInfo.headimgurl" class="mr10">
          <img
            src="https://www.zizhihelp.com/upload/static/web/mine_touxiang.png"
          />
        </el-avatar>

        <div class="info">
          <div class="name">{{ userInfo.nickname }}</div>

          <div class="phone">{{ userInfo.phone }}</div>
        </div>
      </div>

      <div class="card">
        <img
          v-if="userInfo.status == '未开通'"
          class="huiyuan_bg"
          src="https://www.zizhihelp.com/upload/static/web/mine_vip_bg_usr.png"
        />
        <img
          v-if="userInfo.status == '已开通'"
          class="huiyuan_bg"
          src="https://www.zizhihelp.com/upload/static/web/mine_vip_bg_vip.png"
        />

        <div class="title">
          <img
            v-if="userInfo.status == '未开通'"
            class="vip_title"
            src="https://www.zizhihelp.com/upload/static/web/mine_vip_usr_title.png"
          />
          <img
            v-if="userInfo.status == '已开通'"
            class="vip_title"
            src="https://www.zizhihelp.com/upload/static/web/mine_vip_vip_title.png"
          />

          <div>
            {{
              userInfo.status == "未开通"
                ? `未开通`
                : `有限期至：${userInfo.upEndTime}`
            }}
          </div>
        </div>
      </div>

      <div class="server_title">
        <img
          class="server_title_img"
          src="https://www.zizhihelp.com/upload/static/web/mine_fuwubiao.png"
          alt=""
        />
      </div>

      <div class="server_table">
        <div class="server_table_row title_row">
          <div class="server_table_cell server_table_cell2">功能</div>
          <div class="server_table_cell server_table_cell1">免费会员</div>
          <div class="server_table_cell server_table_cell1">VIP会员</div>
          <!-- <div class="server_table_cell server_table_cell1">SVIP升级版</div> -->
        </div>

        <div v-for="(item, index) in serviceData">
          <div
            :class="[
              'server_table_row',
              index % 2 != 0 ? 'single_row' : 'plural_row',
            ]"
          >
            <div class="server_table_cell server_table_cell2">
              {{ item.function }}
            </div>

            <div class="server_table_cell server_table_cell1">
              <img
                v-if="item.normal == '1'"
                class="icon_gou"
                src="https://www.zizhihelp.com/upload/static/gou.png"
              />

              <img
                v-else
                class="icon_cha"
                src="https://www.zizhihelp.com/upload/static/cha.png"
              />
            </div>

            <div class="server_table_cell server_table_cell1">
              <img
                v-if="item.vip == '1'"
                class="icon_gou"
                src="https://www.zizhihelp.com/upload/static/gou.png"
              />

              <img
                v-else
                class="icon_cha"
                src="https://www.zizhihelp.com/upload/static/cha.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right_page page">
      <div class="select_wrapper">
        <div
          :class="['select_item', index == selectActiveIndex ? 'active' : '']"
          v-for="(item, index) in selectData"
          :key="index"
          @click="handleChooseType(index)"
        >
          <img
            class="icon_vip"
            mode="heightFix"
            :src="`https://www.zizhihelp.com/upload/static/${item.name}.png`"
          />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="scroll_wrapper scrollbar">
        <div
          :class="['scroll_item', index == priceActiveIndex ? 'active' : '']"
          v-for="(item, index) in priceList"
          :key="index"
          @click="handleChoosePrice(index)"
        >
          <span class="price_name">{{ item.desc }}</span>
          <div class="price">
            ¥
            <span class="num">{{ item.discountPrice }}</span>
          </div>
          <span class="original_price">
            {{ `原价：¥${item.originalPrice}` }}
          </span>
        </div>
      </div>

      <div class="pay_way">
        <div class="title">支付方式</div>

        <div class="pay_list">
          <div
            class="pay_item"
            v-for="(item, index) in payWayList"
            :key="index"
            @click="handleChoosePayWay(index)"
          >
            <img
              class="icon_pay"
              :src="`https://www.zizhihelp.com/upload/static/${item.value}.png`"
            />

            <div class="name_wrapper">
              <span>{{ item.name }}</span>

              <img
                v-if="index == payWayActiveIndex"
                class="icon_choose"
                src="https://www.zizhihelp.com/upload/static/gou_fill.png"
              />

              <img
                v-else
                class="icon_choose"
                src="https://www.zizhihelp.com/upload/static/quan.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="notice">
        <span class="deep_color">购买须知：</span>
        支付后联系客服可开发票，支付即同意
        <span class="deep_color cursorP" @click="toAgreement">
          《会员服务协议》
        </span>
      </div>

      <div class="order_amount">
        <div class="order_amount_wrapper">
          <div class="title">订单金额</div>

          <div class="amount">¥{{ price }}</div>
        </div>

        <div class="origin_amount">原价：¥{{ originPrice }}</div>
      </div>

      <div class="pay_btn" @click="payment">立即支付</div>
    </div>

    <!-- 协议 -->
    <customDialog v-model="protocolShow" title="隐私协议" width="1120px">
      <div class="protocol" v-html="protocolText"></div>

      <div style="margin-top: 20px; text-align: center">
        <el-button type="primary" @click="protocolShow = false">
          已阅读并关闭
        </el-button>
      </div>
    </customDialog>

    <!-- 展示微信二维码 -->
    <customDialog
      v-model="codeShow"
      title="微信支付"
      width="600px"
      @change="onClose"
    >
      <div class="tac">
        <img class="code" :src="weixinCode" alt="" />

        <div class="mt20">请使用微信扫码支付</div>
      </div>

      <div class="mt20 tar">
        <el-button type="primary" @click="refreshPay"> 刷新支付结果 </el-button>

        <el-button @click="onClose"> 取消支付 </el-button>
      </div>
    </customDialog>
  </div>
</template>

<script>
/* 会员中心 */
import local from "@/utils/local.js";
import {
  getVipAccess,
  getVipPriceByType,
  openVipByWeiXin,
  getOrderStatus,
} from "@/api/personalCenter";
import { getDict } from "@/api/common";
import { getProtocol } from "@/api/login";

export default {
  data() {
    return {
      userInfo: {}, // 用户信息
      serviceData: [],
      selectData: [], // 选择vip类型
      selectActiveIndex: 0,
      priceList: [], // 价格数据
      priceActiveIndex: 0,
      payWayList: [], // 支付类型
      payWayActiveIndex: 0,
      price: 0, // 支付价格
      originPrice: 0, // 原价
      protocolShow: false, // 控制协议显示隐藏
      protocolText: "", // 协议内容
      weixinCode: "", // 微信二维码url
      codeShow: false, // 控制微信二维码显示隐藏
      orderNo: "", // 订单号
      timer: null, // 定时器
    };
  },

  methods: {
    /* 获取数据 */
    // 获取数据
    async getData() {
      // 服务表数据
      const serviceData = await getVipAccess({ userId: this.userInfo.id });
      this.serviceData = serviceData.data;

      // 获取vip类型并根据类型获取价格列表
      const selectData = await getDict({ source: "vipType" });
      this.selectData = selectData.data;
      // 获取价格数据-需要上一条执行完成之后
      let priceList = await getVipPriceByType({
        typeCode: this.selectData[0].value,
      });
      priceList = priceList.data;
      this.priceList = priceList;
      this.price = priceList[0].discountPrice;
      this.originPrice = priceList[0].originalPrice;

      // 获取支付方式
      const payWayList = await getDict({ source: "payType" });
      this.payWayList = payWayList.data;

      // 获取协议内容
      const { code, data } = await getProtocol({ page: "webProtocol" });
      if (code == 0) {
        this.protocolText = data.content;
      }
    },

    /* 操作 */
    // 选择开通的vip类型
    async handleChooseType(index) {
      this.selectActiveIndex = index;
      // 获取价格数据
      const priceList = await getVipPriceByType({
        typeCode: this.selectData[index].value,
      });
      this.priceList = priceList.data;
      this.price = priceList[0].discountPrice;
      this.originPrice = priceList[0].originalPrice;
    },
    // 选择价格
    handleChoosePrice(index) {
      this.priceActiveIndex = index;
      this.price = this.priceList[index].discountPrice;
      this.originPrice = this.priceList[index].originalPrice;
    },
    // 选择支付方式
    handleChoosePayWay(index) {
      this.payWayActiveIndex = index;
    },
    // 跳转到协议页面
    toAgreement() {
      this.protocolShow = true;
    },
    // 立即支付
    async payment() {
      if (!this.userInfo.phone) {
        this.$message.error("请先前往账号设置绑定手机号！");
        return;
      }
      let params = {
        wxUserId: this.userInfo.id,
        payType: "wx", // 写死为wx因为当前只做微信支付
        vip: this.priceList[this.priceActiveIndex],
      };

      let res = await openVipByWeiXin(params);
      if (res.code === 0) {
        this.weixinCode = res.data.qrCode;
        this.orderNo = res.data.orderNo;
        this.codeShow = true;
        this.refreshPay();
        this.timer = setInterval(() => {
          this.refreshPay();
        }, 2000);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 刷新支付结果
    async refreshPay() {
      let params = {
        orderNo: this.orderNo,
      };
      const { code, data } = await getOrderStatus(params);
      if (code === 0 && data.status == "支付成功") {
        this.$message.success("支付成功！");
        this.codeShow = false;
        clearInterval(this.timer);
      }
    },
    // 取消支付
    onClose() {
      this.codeShow = false;
      clearInterval(this.timer);
    },

    /* 初始化 */
    __init__() {
      this.userInfo = local.get("userInfo");
      if (!this.userInfo.headimgurl) {
        this.userInfo.headimgurl =
          "https://www.zizhihelp.com/upload/static/web/mine_touxiang.png";
      }
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.vip_center {
  padding: 40px;
  display: flex;

  .page {
    flex: 1;
    padding: 22px 30px;
    box-shadow: 0px 0px 6px 0px rgba(157, 195, 221, 0.5);
    border-radius: 8px;
  }

  .left_page {
    margin-right: 40px;

    .userInfo {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .info {
        flex: 1;
        display: flex;
        flex-direction: column;

        .name {
          color: #333;
          font-size: 18px;
          margin-bottom: 8px;
          font-weight: bold;
        }

        .phone {
          color: #666;
          font-size: 16px;
        }
      }
    }

    .card {
      padding-bottom: 0px;
      margin: 20px 0px;
      position: relative;

      .huiyuan_bg {
        width: 100%;
        // max-height: 170px;
      }

      .title {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 16px;
        position: absolute;
        top: 28px;
        left: 23px;

        .vip_title {
          // height: 26px;
          width: 122px;
          margin-bottom: 20px;
        }
      }
    }

    .server_title {
      text-align: center;
      margin-bottom: 16px;

      .server_title_img {
        width: 315px;
      }
    }

    .server_table {
      width: 100%;
      border-left: 1px solid #accef9;
      border-top: 1px solid #accef9;
      font-size: 14px;

      .server_table_row {
        display: flex;
        color: #333333;

        .server_table_cell {
          border-right: 1px solid #accef9;
          border-bottom: 1px solid #accef9;
          word-break: break-all;
          text-align: center;
          line-height: 3;
        }

        .server_table_cell1 {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .server_table_cell2 {
          flex: 1.2;
        }
      }

      .title_row {
        color: #fff;
        background-color: #4f7bff;
      }
      .plural_row {
        background-color: #ddecff;
      }

      .icon_gou {
        width: 20px;
        height: 17px;
      }

      .icon_cha {
        width: 12px;
        height: 12px;
      }
    }
  }

  .right_page {
    .select_wrapper {
      display: flex;
      align-items: center;
      border-radius: 8px;
      margin-bottom: 20px;

      .select_item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        font-size: 16px;
        padding: 12px 20px;
        background-color: #f0f0f0;
        cursor: pointer;

        .icon_vip {
          height: 14px;
          margin-right: 5px;
        }
      }

      .active {
        background-color: #4f7bff;
        border-radius: 8px;
        color: #fff;
        font-weight: bold;
      }
    }

    .scroll_wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-bottom: 26px;

      .scroll_item {
        padding: 10px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        border-radius: 12px;
        white-space: nowrap;
        margin-right: 10px;
        border: 2px solid #e0e0e0;
        cursor: pointer;

        .price_name {
          font-weight: bold;
        }

        .price {
          color: #1d89e3;
          margin: 4px 0px;
          .num {
            font-size: 26px;
            font-weight: bold;
          }
        }

        .original_price {
          color: #999999;
          font-size: 12px;
          text-decoration: line-through;
        }
      }

      .active {
        border: 2px solid #2e5edd;
        box-shadow: 0px 0px 4px 0px rgba(153, 153, 153, 0.5);
      }
    }

    .pay_way {
      .title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .pay_list {
        .pay_item {
          display: flex;
          align-items: center;
          padding: 16px;
          background-color: #f9fafc;
          color: #333333;
          font-size: 16px;
          border-radius: 8px;
          margin-bottom: 12px;
          cursor: pointer;

          .icon_pay {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .name_wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon_choose {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }

    .notice {
      font-size: 14px;
      color: #999999;
      margin-bottom: 20px;

      .deep_color {
        color: #666666;
      }
    }

    .order_amount {
      border-radius: 8px;
      background: #f9fafc;
      padding: 20px 16px;
      margin-bottom: 40px;

      .order_amount_wrapper {
        display: flex;
        align-content: center;
        justify-content: space-between;
        color: #333333;
        font-size: 16px;
        margin-bottom: 10px;
        .amount {
          color: #eb4f3d;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .origin_amount {
        color: #999;
        font-size: 16px;
        text-decoration: line-through;
        text-align: right;
      }
    }

    .pay_btn {
      width: 100%;
      background: #4f7bff;
      border-radius: 8px;
      padding: 12px 0px;
      color: #fff;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .protocol {
    height: 500px;
    overflow-y: scroll;
    line-height: 1.8;

    /deep/.ql-align-center {
      text-align: center !important;
    }

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }
}

.code {
  width: 250px;
  height: 250px;
}
</style>
