// 个人中心模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 获取订单记录列表
export const getVipPayRecord = (params) => {
  return request({
    url: '/api/pay/getVipPayRecord',
    method: "get",
    params
  })
}
// 获取我的公司列表数据
export const getMyCompanyList = (params) => {
  return request({
    url: '/api/companyIn/getByUserId',
    method: "get",
    params
  })
}
// 获取聊天设置信息
export const getChatSetting = (params) => {
  return request({
    url: '/api/chatSet/get',
    method: "get",
    params
  })
}
// 保存聊天设置信息
export const saveChatSetting = (data) => {
  return request({
    url: '/api/chatSetSave/save',
    method: "post",
    data
  })
}
// 获取用户信息
export const getUserInfo = (params) => {
  return request({
    url: '/api/user/get',
    method: "get",
    params
  })
}
// 保存头像
export const saveHeaderImage = (params) => {
  return request({
    url: '/api/user/saveHeaderImage',
    method: "get",
    params
  })
}
// 验证手机号和验证码是否正确
export const authPhone = (params) => {
  return request({
    url: '/api/user/authPhone',
    method: "get",
    params
  })
}
// 注销账号
export const logout = (params) => {
  return request({
    url: '/api/user/logout',
    method: "get",
    params
  })
}
// 保存个人信息
export const saveWxUser = (data) => {
  return request({
    url: '/api/user/saveWxUser',
    method: "post",
    data
  })
}

// 推广信息-获取推广金额
export const getAmount = (params) => {
  return request({
    url: '/api/distribution/getAmount',
    method: "get",
    params
  })
}

// 推广信息-获取推广记录
export const getAmountHis = (params) => {
  return request({
    url: '/api/distribution/getAmountHis',
    method: "get",
    params
  })
}

// 推广信息-获取提现记录
export const getWithdrawHis = (params) => {
  return request({
    url: '/api/distribution/getWithdrawHis',
    method: "get",
    params
  })
}

// 申请提现
export const applyWithdraw = (params) => {
  return request({
    url: '/api/distribution/applyWithdraw',
    method: "get",
    params
  })
}

// 获取下级客户总数
export const getTotalClient = (params) => {
  return request({
    url: '/api/distribution/nexList',
    method: "get",
    params
  })
}
// 获取下下级客户列表
export const getNextClientList = (params) => {
  return request({
    url: '/api/distribution/nexNextList',
    method: "get",
    params
  })
}
// vip-获取等级权限
export const getVipAccess = (params) => {
  return request({
    url: '/api/pay/getVipAccess',
    method: "get",
    params
  })
}
// 通过vip等级查询对应价格
export const getVipPriceByType = (params) => {
  return request({
    url: '/api/pay/getVipPriceByType',
    method: "get",
    params
  })
}
// 添加订单-支付方式为微信
export const openVipByWeiXin = (data) => {
  return request({
    url: '/api/pay/openVipByWeiXinCode',
    method: "post",
    data
  })
}

// 查询订单状态
export const getOrderStatus = (params) => {
  return request({
    url: '/api/wxPay/getOrderStatusByNo',
    method: "get",
    params
  })
}
